/*CSS RESET*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
}

body {
  line-height: 1;
}
body {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}


ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0
}

.sr-only-focusable:active,.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto
}

/*Bouce close window*/
#ouibounce-modal h4:before {
  background: #ff851d;
  content: "";
  height: 100%;
  width: 6px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 16px;
}

section.expertise-col a:hover {
  background: #de122e;
  border-color: #de122e;
  color: #fff;
}

#ouibounce-modal .modal-body a {
  padding-right: 62px;
  margin-bottom: 23px;
  font-size: 14px;
  margin-top: 10px;
}

.modal-footer a {
  cursor: pointer;
  border: #0c8a41 3px solid;
  background: #fff;
  border-radius: 72px;
  text-align: center;
  box-shadow: #000 0 0 10px;
  display: block;
  padding: 15px;
}

@-webkit-keyframes fadein {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}

@-ms-keyframes fadein {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}

@keyframes fadein {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}

@-webkit-keyframes popin {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0
  }

  85% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      opacity: 1
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }
}

@-ms-keyframes popin {
  0% {
      -ms-transform: scale(0);
      transform: scale(0);
      opacity: 0
  }

  85% {
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      opacity: 1
  }

  100% {
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }
}

@keyframes popin {
  0% {
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      opacity: 0
  }

  85% {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      opacity: 1
  }

  100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }
}

#ouibounce-modal {
  display: none;
}

.email-cta {
  background-color: transparent !important;
  border: 0;
  padding: 0!important;
  margin: 0!important;
  font-size: 14px !important;
  text-transform: lowercase !important;
}

section.testimonail .col-md-6:nth-child(1):before {
  display: none !important;
}

img.header-logo {
  width: 160px;
}

.popupform ul li {
  text-align: left;
  position: relative;
  width: 48%;
  margin: 8px 3px;
  display: inline-block;
}

.popupform ul li input[type="text"], .popupform ul li input[type="email"], .popupform ul li textarea,.popupform ul li input[type="number"] {
  background: #fafafa;
  border: #dcdcdc 1px solid;
  padding: 10px 10px 10px 34px;
  width: 100%;
  border-radius: 7px;
  font-size: 14px;
  color: #000;
  box-shadow: none !important;
  outline: none 0px !important;
}

.popupform ul li i {
  position: absolute;
  top: 12px;
  left: 10px;
}

.popupform ul li.first,.popupform ul li:nth-child(4) {
  width: 98%;
}

.popupform ul li textarea {
  height: 110px;
}

.popupform ul li.last {
  text-align: center;
  width: 100%;
}

.popupform ul li button {
  background: #17bec8;
  color: #fff;
  font-size: 18px;
  border: #17bec8 1px solid;
  text-transform: uppercase;
  font-weight: 600;
  padding: 14px 50px;
  letter-spacing: 1px;
  border-radius: 3px;
  outline: 0px none;
  width: 100%;
  display: inline-block;
}

.popupform ul li button:hover {
  background: #212121;
  border-color: #212121;
}

.popupform ul li input[type="text"]:focus, .popupform ul li input[type="email"]:focus, .popupform ul li textarea:focus {
  border: #0c8a41 1px solid !important;
}
.main-popup .modal-content{
  background-color: transparent;
}
.popupform {
  width: 500px;
  border-radius: 23px;
  padding: 31px;
  overflow: visible;
  border-bottom: #0c8a41 5px solid;
  background-color: #fff;
}

.popupform h2 {
  font-size: 28px;
  color: #000000;
  font-weight: 900;
  padding-left: 15px;
  border-left: #17bec8 6px solid;
  margin-bottom: 12px;
  line-height: 35px;
  margin-top: 0;
  text-transform: uppercase;
}

.popupform h2+p {
  line-height: 19px;
  font-size: 14px;
  margin-bottom: 12px;
}

.popupform .close-mark {
  position: absolute;
  border: #17bec8 3px solid;
  background: #fff;
  border-radius: 72px;
  text-align: center;
  box-shadow: #000 0 0 10px;
  right: -18px;
  top: -11px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupform .close-mark i {
  color: #0c8a41;
  font-size: 25px;
  right: 4px;
  background: transparent;
  padding-top: 0;
  font-size: 20px;
}

input.required.error, textarea.required.error, select.required.error {
  border-bottom: red 2px solid!important;
  color: red!important;
}

label.error {
  display: none!important;
}

.modal-footer a:after {
  content: "x";
  position: absolute;
  top: 3px;
  right: 3px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial,Helvetica Neue,Helvetica,sans-serif;
  color: #0c8a41;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background .2s;
  box-sizing: border-box;
  z-index: 2;
}

#ouibounce-modal .modal-footer {
  position: absolute;
  top: -10px;
  right: -8px;
  border: 0px none;
}

div#btmsticky b {
  color: #fcb314;
  font-size: 24px;
}

div#btmsticky span {
  display: block;
  color: #fcb314;
  font-size: 29px;
}

div#btmsticky .content a {
  display: inline-block;
  /* width: 250px; */
  position: relative;
  top: 15px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600!important;
  border: 0px none;
  cursor: pointer;
  color: #000;
  padding: 18px 6px;
  font-size: 15px;
  margin: 0 0 0 19px;
  border-radius: 2px;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -o-transition: all .1s linear;
  -ms-transition: all .1s linear;
  transition: all .1s linear;
  background: #fcb314;
}

div#btmsticky h2 {
  font-size: 24px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  width: 61%;
  color: #f5f5f5;
  margin: 13px 0 0 0;
}

.btm-sticky {
  position: fixed;
  bottom: -1px;
  display: none;
  left: 0;
  width: 100%;
  background: #2b2b2b;
  padding: 16px 0;
  border-top: #000 2px solid;
  z-index: 4;
}

.hide {
  opacity: 0;
  left: -100%;
}

.show {
  opacity: 1;
  left: 0;
}



footer .col-md-7 li+li a i {
  color: #fff;
  font-size: 20px;
}
section.banner p span {
  color: #1ed4c6;
  font-weight: 600;
}

section.portfolio h2+p {
  font-size: 17px;
  padding: 0 70px;
  margin-bottom: 44px;
  color: #676767;
}

div#portfolio .tile {
  margin-bottom: 0;
  display: none;
}body{
  font-family: 'Poppins', sans-serif;

}
header {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 20px 0 0px;
}

section.banner {
  background: url(./assets/images/banner1.jpg) no-repeat right top #081038;
  background-size: cover;
  text-align: center;
  padding: 150px 0 70px;
  color: #fff;
}

.tile.scale-anm {
  display: inline-block !important;
}
.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}


.time_circles>div {
  position: absolute;
  text-align: center;
}


.time_circles>div>h4 {
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Century Gothic', Arial;
}

.time_circles>div>span {
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Century Gothic', Arial;
  font-size: 300%;
  margin-top: 0.4em;
  font-weight: bold;
}

div.CountDownTimer {
  width: 300px;
  margin: 0 auto;
  text-shadow: #000 1px 1px 2px;
  color: #fff;
}

.cus-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQImWNguAkAANwA2ohRxm8AAAAASUVORK5CYII=) repeat;
  top: 0;
  left: 0;
  z-index: 998;
  display: none;
}

.field label.error {
  display: none!important;
}

input.error,
textarea.error,
select.error {
  background-color: #FFD7D7!important;
  color: #111!important;
}


header ul {
  text-align: right;
  color: #fff;
  margin: 0;
  margin-top: 25px;
}

header ul li {
  display: inline-block;
  text-align: left;
}

header ul li.last a {
  display: block;
  border: #0fa3cb 2px solid;
  color: #fff;
  padding: 11px 42px;
  border-radius: 136px;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
}

header ul li.last {
  margin-left: 23px;
}

header ul li a {
  display: block;
}

header ul li.first span {
  display: block;
  color: #00c581;
  font-size: 17px;
}

header ul li.first {
  font-size: 22px;
}

section.banner h1 {
    margin: 20px 0 7px 0;
  color: #fff;
  font-size: 57px;
  font-weight: 500;
  text-transform: uppercase;
}

section.banner p {
  font-size: 28px;
}

ul.banner-features {
  text-transform: uppercase;
  margin: 30px 0 0;
  font-size: 14px;
}

ul.banner-features li {
  display: inline-block;
  margin: 0 8px;
  letter-spacing: 0.5px;
}

ul.banner-features+a+a {
  background: #047ece !important;
}

span.bbb {
  display: block;
}

ul.banner-features li i {
  color: #0fa3cb;
  font-size: 17px;
  position: relative;
  top: -2px;
}

ul.banner-features+a,ul.banner-features+a+a {
  display: inline-block;
  width: 270px;
  text-align: center;
  margin: 29px 7px 0;
  background: #0fa3cb;
  color: #fff;
  text-transform: uppercase;
  padding: 14px 10px;
  font-size: 17px;
  border-radius: 80px;
  margin-bottom: 23px;
  text-shadow: #000 0 1px 1px;
  font-weight: 500;
}

ul.banner-logos {
  margin: 70px 0 0 0;
  text-align: center;
}

ul.banner-logos li {
  display: inline-block;
  margin: 0 25px;
}

section.banner-btm [class^="col-"] {
  text-align: center;
  background: #fa8900;
  color: #fff;
  padding: 28px;
  height: 128px;
  overflow: hidden;
}

section.banner-btm [class^="col-"]+[class^="col-"] {
  background: #3852f3;
}

section.banner-btm [class^="col-"]+[class^="col-"]+[class^="col-"] {
  background: #00c581;
}

section.banner-btm [class^="col-"] i {
  display: inline-block;
}

section.banner-btm [class^="col-"] h6 {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  padding-left: 15px;
  line-height: 23px;
  font-weight: 500;
}

section.hm-about {
  padding: 60px 0;
  
}

section.hm-about h2 {
  color: #232323;
  font-size: 31px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 49px;
  margin-top: 0;
}

section.hm-about h2+p {
  font-size: 14px;
  color: #545454;
}

section.hm-about h2+p br {
  display: none;
}

section.hm-about ul li {
  display: inline-block;
  width: 49%;
  margin-bottom: 21px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

section.banner-btm:after {
  display: block;
  clear: both;
  content: "";
}

section.hm-about ul li i {
  color: #44c97c;
  font-size: 16px;
  margin-right: 5px;
}

section.hm-about ul {
  margin: 0;
}

section.hm-about a {
  display: inline-block;
  text-align: center;
  background: #0fa3cb;
  color: #fff;
  text-transform: uppercase;
  padding: 11px 40px;
  font-size: 17px;
  border-radius: 80px;
  font-weight: 500;
  margin-top: 18px;
  letter-spacing: 0.5px;
}

section.portfolio {
  text-align: center;
  background: #ededed;
  padding: 80px 0;
}

section.portfolio h2 {
  margin: 0;
  color: #1f1f1f;
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 23px;
  text-transform: capitalize;
}

section.portfolio .toolbar.mb2.mt2 {
  margin-bottom: 46px;
}

section.portfolio .btn {
  background: #3cca79;
  color: #fff;
  outline: 0px none !important;
}

section.portfolio .btn:active, section.portfolio .btn:focus, section.portfolio .btn:visited {
  background: #28af62;
}

section.features-col {
  text-align: center;
  padding: 80px 0;
}

section.features-col h2 {
  margin: 0 0 62px;
  font-size: 31px;
  font-weight: 600;
  line-height: 39px;
}

section.features-col h2+p {
  font-size: 21px;
  margin-bottom: 83px;
  color: #9a9a9a;
  display: none;
}

section.cta-book {
  background: #0fa3cb;
  padding: 60px 0 0;
  color: #fff;
  font-size: 22px;
}

section.cta-book .col-md-7 {
  padding-top: 43px;
}

section.cta-book h2 {
  font-size: 40px;
  font-weight: 700;
}

section.cta-book h2 span {
  color: #000000;
  font-weight: 700;
}

section.cta-book ul {
  margin: 30px 0 0;
}

section.cta-book ul li {
  display: inline-block;
  margin-right: 23px;
  font-size: 14px;
  position: relative;
  padding-left: 37px;
}

section.cta-book ul li span {
  display: block;
  font-size: 14px;
}

section.cta-book ul li i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 21px;
}

section.cta-book a {
  display: inline-block;
  text-align: center;
  background: #047ece;
  color: #fff;
  text-transform: uppercase;
  padding: 9px 40px;
  font-size: 18px;
  border-radius: 80px;
  font-weight: 500;
  margin-top: 19px;
}

section.testimonail {
  padding: 63px 0;
  background: #fff;
}

div#testimonails-slider .item {
  background: #f5f5f5;
  padding: 24px 14px;
  color: #8c8989;
}

div#testimonails-slider .item p {
  line-height: 24px;
  font-style: italic;
  height: 218px;
}

div#testimonails-slider .item h6 {
  color: #000;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

div#testimonails-slider .item h6 span {
  display: block;
  color: #4e4c4c;
  font-weight: 400;
  font-size: 13px;
  margin-top: 6px;
}

section.testimonail h2 {
  color: #232323;
  font-size: 41px;
  font-weight: 400;
  margin-bottom: 32px;
  line-height: 48px;
  position: relative;
  z-index: 1;
}

section.testimonail h2+p {
  font-size: 18px;
  color: #8a8a8a;
}

section.testimonail .col-md-6:nth-child(1) {
  padding-right: 72px;
  position: relative;
}



section.brands {
  margin-top:30px;
  background: #213047;
  text-align: center;
}

section.brands ul li {
  display: inline-block;
  padding: 30px 25px;
}

section.brands ul {
  margin: 0;
}



footer {
  background: #1b1b1b;
  padding: 40px 0 0;
  font-size: 15px;
  color: #889ec6;
  clear: both;
}
footer a {
  color: #889ec6;
}
footer ul li {
  color: #fff;
  text-align: left;
  font-size: 15px;
  position: relative;
  margin-bottom: 14px;
  padding-left: 29px;
  font-weight: 400;
}

footer ul li i {position: absolute;left: 0;top: 4px;color: #3c4e6f;}

footer ul li a {
  color: #fff;
}

footer ul {
  margin-top: 117px;
  float: right;
}

.copyright {
  background: #141414;
  font-size: 15px;
  padding: 20px 0;
  margin-top: 22px;
}

i.top-scroll {
  position: absolute;
  right: 0;
  top: -39px;
  cursor: pointer;
}

.copyright .container {
  position: relative;
}

.copyright p {
  margin: 0;
}

footer img {
  margin-bottom: 13px;
}

footer i.fa.fa-phone {
  font-size: 21px;
}

section.testimonail .col-md-6:nth-child(1):before {
  content: open-quote;
  font-size: 642px;
  color: #f5f5f5;
  position: absolute;
  right: 0;
  top: -160px;
  z-index: 0;
}


div#bouncepopupform input[type="submit"]+input[type="submit"] {
  background: red;
  border: red 1px solid;
}


div#bouncepopupform {
  position: fixed;
  top: 10%;
  left: 50%;
  width: 480px;
  height: 500px;
  margin: 0;
  padding: 18px;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  border-radius: 20px;
  text-align: left;
  z-index: 99994;
  background: #fff;
  margin-left: -240px;
}

div#bouncepopupform h2 {
  font-size: 19px;
  text-align:  left;
  font-weight: 700;
  margin-top: 8px;
  line-height: 27px;
  padding-right: 43px;
}

div#bouncepopupform h2 span {
  display:  block;
  line-height:  normal;
  font-size: 16px;
}

div#bouncepopupform h2 span b {
  color: #008c3a;
  font-weight: 800;
}

div#bouncepopupform input[type="submit"] {
  width: initial;
  font-size: 13px;
  padding: 13px 16px !important;
  margin: -13px 4px 0;
}

div#bouncepopupform textarea {
  height: 120px;
}

.bouncepopup-overlay {
  background: #000000de;
  position: fixed;
  width:  100%;
  height: 100%;
  top: 0;
  z-index: 99;
}

form.bounce-popupform li {
  margin-bottom: 0 !important;
}
div#CountDownTimerMini {
  color: #000 !important;
  text-shadow: none !important;
  width: 250px;
  margin: 0 auto;
  /* position: absolute; */
  right: 0;
}
a.bounce-close {
  background: #17bec8;
  color: #fff !important;
  width: 37px;
  height: 37px;
  position:  absolute;
  right: 6px;
  top: 7px;
  box-shadow: #00000082 0 0px 6px;
  border-radius:  100%;
  padding-top: 5px;
  font-size: 15px;
  font-weight: 600;
  border: #ffffff 3px solid;
  text-align:  center;
}

.bouncepopup-overlay {
  display:  none;
}



div#bouncepopupform h6 {
  color: #17bec8;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-left: 21px;
  padding-top: 0;
  text-align: left;
  margin-bottom: 16px;
}


.counter-col {
  background: #ffffff;
  margin: 0;
  padding: 0;
  padding-left: 13px;
  position: relative;
  padding: 0;
}
.video-section .row {
  align-items: center;
  display: flex;
}
section.hm-about  .video-section h2 {
  font-size: 57px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}
.video-section h4 {
  font-size: 22px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.video-section {
  background-image: radial-gradient(circle, rgba(68,69,73,1) 10%, rgba(27,26,30,1) 100%);
      padding: 50px 0px;
  color: white;
  margin-top: 50px;
}
.portfolio .container-fluid{
  padding: 0 3%;
}

.image-boxwrpr{transition: 0.5s; max-height: 600px; position: relative;  overflow: hidden; cursor: pointer;}
/* .image-boxwrpr img{max-height: 100vh;width: 100%;height: 600px;object-fit: cover;} */
.portfolio .row{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.image-boxwrpr:hover::after{
  width: 100%;
  height: 100%;
  content: '';
  background-color: #000000de;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.content-wrpr{
  padding: 5%;
  opacity: 0;
  position: absolute;
  flex-direction: column; align-items: center; justify-content: center; 
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  transform:translateY(100%);
  z-index: 1;
  display: flex;
}
.image-boxwrpr:hover .content-wrpr{
opacity: 1;
transition: 0.5s;
transform:translateY(0);
}
.content-wrpr span{
  position: absolute;
  background-color: #0fa3cb;
  transition: .5s
}
.content-wrpr .one{
  height: 0;
  width: 3px;
  top: 0;
  left: 0;
}
.content-wrpr .two{
  height: 3px;
  width: 0;
  bottom: 0;
  left: 0;
}
.content-wrpr .three{
  height: 0;
  width: 3px;
  bottom:0;
  right:0
}
.content-wrpr .four{
  height: 3px;
  width: 0;
  top: 0;
  right: 0;
}
.content-wrpr:hover .one,
.content-wrpr:hover .three{
  height: 100%;
}
.content-wrpr:hover .two,
.content-wrpr:hover .four{
  width: 100%;
}
.content-wrpr:hover{
  transition-duration: .5s;
  transition-delay: .5s;
  opacity: .5;
}
.content-wrpr *{color: #fff;}
.content-wrpr ul{text-align: left;padding: 1rem 0 0 2rem;margin-bottom: 0;}
.content-wrpr ul li{list-style: disc;margin-bottom: 1rem;}


.countdown {
  display: flex;
  justify-content: center;
  margin-bottom: 15px
}

.time-unit {
  align-items: center;
  background-color: initial;
  border: 4px solid #1ed4c6;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  height: 62px;
  justify-content: center;
  margin: 0 9px;
  padding: 0;
  text-align: center;
  width: 62px
}

.time-value {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 18px
}

.time-label {
  color: #fff;
  font-family: Poppins,sans-serif;
  font-size: 8px;
  line-height: 6px;
  margin-top: 3px
}
.whatlogoslider.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

@media (max-width:850px){
  section.banner {
  position: relative;
  z-index: 1;
}
section.banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
}
header img {
  height: 50px;
}
section.banner {
  background-position: -72px;
}
header {
  z-index: 11;
}
.video-section .row {
  display: block;
}
.video-section .row iframe {
  border: 4px solid #eaeaea;
  height: auto;
}
section.hm-about .video-section h2 {
  font-size: 42px;
  margin-bottom: 0px;
}
.video-section h4 {
  font-size: 16px;
  margin-bottom: 20px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 0;
}
}

div#portfolio {    display: flex;   flex-flow: wrap;   }

div#portfolio .tile {    width: 20%;    padding: 5px;   }

div#portfolio .tile img {width: 100%;}




.pricing-sec .col-md-12.megapkg img {
  position: relative;
  z-index: 3;
}

section.pricing-sec [class^="col-md-4"]:last-child>div {
  margin-right: 0;
}
.pricing-sec .col-md-12.megapkg {
  background: #f1f1f1;
  padding: 15px;
  border: 5px dashed #e3e3e3;
  color: #808080;
  margin-top: 15px;
      position: relative;
}

.pricing-sec .col-md-12.megapkg>div {
  background: #0e54d0;
  padding: 0px 0px 0px 20px;
}

.pricing-sec .col-md-12.megapkg>div h3, .pricing-sec .col-md-12.megapkg>div h4, .pricing-sec .col-md-12.megapkg>div h5 {
  display: inline-block;
  margin: 0;
}

.pricing-sec .col-md-12.megapkg>div h3 {
  font-size: 70px;
  /* line-height: 36px; */
  color: #ffcd1e;
  font-weight: 700;
  text-align: center;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.pricing-sec .col-md-12.megapkg>div h3 span {
  display: block;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  top: -9px;
}

.pricing-sec .col-md-12.megapkg>div h4 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  padding: 0 20px;
}

.pricing-sec .col-md-12.megapkg>div h4 span {
  display: block;
  font-size: 22px;
  font-weight: 300;
}

.pricing-sec .col-md-12.megapkg>div h5 {
  background: #0c4ab7;
  display: inline-block;
  padding: 30px 30px;
  font-size: 60px;
  color: #ffcd1e;
  font-weight: 700;
}

.pricing-sec .col-md-12.megapkg>div h5 span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  font-style: italic;
  text-align: right;
}

.pricing-sec .col-md-12.megapkg>div a {
  background: red;
  padding: 15px 40px;
  margin-left: 20px;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  border: 2px solid #0f49b1;
  outline: none;
}


section.pricing-sec [class^="col-md-4"]>div ul li b {
  color: #000;
}

section.pricing-sec [class^="col-md-4"]>div a {
  background: #047ece;
  margin: 30px auto 0px;
  text-align: center;
  width: 150px;
  padding: 10px 0;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 50px;
  display: table;
  position: relative;
}

section.pricing-sec [class^="col-md-4"]>div {
  background: #f1f1f1;
  padding: 0px 0px 20px;
  border: 5px dashed #e3e3e3;
  color: #808080;
  position: relative;
}
section.pricing-sec h2 {
  text-align: center;
  font-size: 48px;
  font-weight: 600;
}

section.pricing-sec h2+p {
  text-align: center;
  color: #a7a7a7;
  font-size: 16px;
}

section.pricing-sec [class^="col-md-4"]>div>div.title-pkg.green-t {
  background: #047ece;
}


section.pricing-sec [class^="col-md-4"]>div ul li {display: block;padding-left: 20px;position: relative;font-size: 14px;margin-bottom: 10px;}

section.pricing-sec [class^="col-md-4"]>div ul li img {
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 5px;
}


section.pricing-sec {
  padding: 50px 0;
}

section.pricing-sec [class^="col-md-4"]>div>div.title-pkg {
  margin: 0;
  background: #363636;
  width: 100%;
  padding: 20px 16px;
  color: #fff;
  margin-bottom: 15px;
  position: relative;
}


section.pricing-sec [class^="col-md-4"]>div>div.title-pkg span {
  display: inline-block;
  font-weight: 300;
  font-size: 17px;
}

section.pricing-sec [class^="col-md-4"]>div>div.title-pkg h3 {
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 3px;
  line-height: 22px;
  position: relative;
  z-index: 1;
  letter-spacing: -0.2px;
}

section.pricing-sec [class^="col-md-4"]>div>div.title-pkg h4 {
  display: inline-block;
  color: #1ed4c6;
  font-size: 26px;
  position: relative;
  font-weight: 700;
  margin: 0;
  padding-left: 0;
  text-shadow: #00000070 0 4px 5px;
  z-index: 1;
}

section.pricing-sec [class^="col-md-4"]>div h5 {
 margin: 0;
  font-size: 12px;
  font-weight: normal;
  color: red;
  margin: 13px 20px;
  text-transform: none;
}


section.pricing-sec [class^="col-md-4"]>div a i img {
  max-width: 50px !important;
  right: -34px;
  position: absolute;
  top: -9px;
}

section.pricing-sec [class^="col-md-4"]>div>div.title-pkg h4 ins {
  color: red;
  text-decoration: line-through;
}

section.pricing-sec [class^="col-md-4"]>div ul {
  margin-left: 15px;
  padding-right: 10px;
}


@media  (max-width: 1720px){
  .image-boxwrpr,.image-boxwrpr img{
      max-height: 500px;
  }
}
@media only screen and (max-width: 900px) and (min-width: 320px) {

  section.pricing-sec [class^="col-md-4"] {width: 100%;padding: 0 15px;}
  
  section.pricing-sec [class^="col-md-4"]>div {
      margin-top: 30px;
  }
      
  
  section.pricing-sec [class^="col-md-4"]>div>div.title-pkg {margin: 0 0 30px;width: 100%;}
  
  section.pricing-sec [class^="col-md-4"]>div a {
      width: 80%;
      font-size: 13px;
      margin: 0 auto;
      display: flow-root;
      margin-bottom: 10px;
  }
  
  a.top-more-btn {
      top: 114px;
  }
  
  section.pricing-sec [class^="col-md-4"]>div a i img {
      right: -10px;
  }
  
  section.pricing-sec [class^="col-md-4"]>div ul, section.pricing-sec [class^="col-md-4"]>div h5 {
      padding: 0 20px;
      font-size: 13px;
  }
  
  section.pricing-sec [class^="col-md-4"]>div>div.title-pkg h3 {
      font-size: 16px;
  }
  
  section.pricing-sec [class^="col-md-4"]>div>div.title-pkg span {
      font-size: 15px;
  }
      
      
      header ul {
      display: none;
  }div#bouncepopupform ul {
      margin: 0;
  }
  
  ul.banner-features+a+a {
      width: 211px;
      font-size: 14px;
      padding: 13px 10px !important;
  }
  div#bouncepopupform {
      width: 98%;
      margin: 0;
      left: 4px;
  }
  
  div#CountDownTimerMini {
      display: none;
  }
  
  div#bouncepopupform h2 {
      text-align: left;
      font-size: 16px;
      line-height: 18px;
  }
  
  div#bouncepopupform h6 {
      text-align: left;
      margin-bottom: 9px;
      font-size: 16px;
  }
  header {
      padding: 20px 0;
      text-align: center;
  }
  
  section.banner {
      padding: 110px 0 10px;
  }
  
  section.banner h1 {
      font-size: 22px;
  }
  
  div#CountDownTimer {
      width: 250px;
  }
  
  section.banner p {
      font-size: 17px;
      font-weight: 600;
  }
  
  ul.banner-features {
      margin-top: 14px;
  }
  
  ul.banner-features li {
      display: block;
      font-size: 12px;
      margin-bottom: 5px;
  }
  
  ul.banner-features+a {
      font-size: 14px;
      margin-top: 13px;
      width: 245px;
      margin-bottom: 0;
      padding: 11px 10px !important;
  }
  
  ul.banner-logos {
      margin-top: 30px;
  }
  
  ul.banner-logos li {
      margin: 0 8px 12px;
  }
  
  ul.banner-logos li img {
      max-width: 91px;
  }
  
  section.banner-btm [class^="col-"] {
      width: 33.33%;
      float: left;
      padding: 11px;
      height: 124px;
  }
  
  section.banner-btm [class^="col-"] h6 {
      font-size: 12px;
      text-align: center;
      line-height: normal;
      text-transform: uppercase;
      padding: 0;
  }
  
  section.banner-btm [class^="col-"] i {
      display: block;
      width: 40px;
      margin: 0 auto;
  }
  
  img {
      max-width: 100%;
  }
  
  section.hm-about {
      padding: 20px 0;
      text-align: center;
      font-size: 13px;
      padding-bottom: 0px
  }
  
  section.hm-about h2 {
      line-height: normal;
      font-size: 18px;
  }
  
  section.hm-about a {
      margin-top: 0;
      margin-bottom: 23px;
  }
  
  section.hm-about ul li {
      text-align: left;
      margin-bottom: 9px;
      font-size: 13px;
  }
  
  section.portfolio {
      padding: 40px 0;
  }
  
  section.portfolio h2 {
      font-size: 22px;
      margin-bottom: 14px;
  }
  
  section.portfolio h2+p {
      font-size: 13px;
      padding: 0;
      margin-bottom: 17px;
  }
  
  section.portfolio .btn {
      font-size: 12px;
      margin: 0 4px 8px;
      letter-spacing: 0.5px;
  }
  
  section.portfolio .toolbar.mb2.mt2 {
      margin-bottom: 13px;
  }
  
  div#portfolio .tile {
      margin-bottom: 1px;
      padding: 6px;
      width: 33.33%;
  }
  
  div#portfolio .tile img {
      width: 100%;
      height: 160px;
  }
  
  section.features-col {
      padding: 30px 0;
  }
  
  section.features-col h2 {
      font-size: 17px;
      line-height: normal;
      margin-bottom: 40px;
  }
  
  section.cta-book img {
      max-width: 200px;
      margin-top: -76px;
  }
  
  section.cta-book {
      text-align: center;
      padding-top: 0;
      margin-top: 93px;
  }
  
  section.cta-book h2 {
      font-size: 25px;
  }
  
  section.cta-book .col-md-7 {
      padding-bottom: 33px;
      padding-top: 20px;
  }
  
  section.cta-book ul {
      display: none;
  }
  
  section.testimonail {
      text-align: center;
      padding: 30px 0;
  }
  
  section.testimonail .col-md-6:nth-child(1) {
      padding: 0 15px;
  }
  
  section.testimonail h2 {
      font-size: 24px;
      line-height: normal;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 10px;
  }
  
  section.testimonail .col-md-6:nth-child(1):before {
      visibility: hidden;
  }
  
  section.testimonail h2+p {
      font-size: 15px;
      margin-bottom: 33px;
  }
  
  
  section.brands {
      margin-top: 7px;
  }
  
  
  footer i.fa.fa-phone {
      font-size: 22px;
  }
      .popupform {
      width: 94%;
      padding: 18px;
  }
  
  .popupform h2 {
      font-size: 21px;
  }
  
  .popupform h2+p {
      line-height: 17px;
      font-size: 12px;
  }
  
  .popupform ul li {
      width: 98%;
  }
  div#bouncepopupform input[type="submit"] {
      margin-top: 10px;
      margin-bottom: 6px;
      font-size: 12px;
      padding: 10px 9px !important;
  }
  .popupform .close-mark {
    right: 19px;
    top: -9px;
    padding-top: 0;
}
    
  section.brands ul li {
      padding: 14px 5px;
      width: 27%;
  }
  
  img {
      max-width: 100%;
  }
  
  footer {
      text-align: center;
      padding: 20px 0 0px 0;
      font-size: 12px;
  }
  
  footer ul {
      float: none;
      text-align: center;
      margin-top: 18px;
  }
  
  footer ul li {
      text-align: center;
      font-size: 15px;
      padding: 0;
      margin-bottom: 7px;
  }
  
  footer ul li i {
      display: inline-block;
  }  
  section.sponsers-list [class^="col-"] {
      border: 0;
      width: 48%;
      display: inline-block;
      padding-left: 72px !important;
  }
   
  section.sponsers-list [class^="col-"] i img {
      max-width: 60%;
  }
  
  section.sponsers-list [class^="col-"] h4 {
      font-size: 21px;
  }
  
  section.sponsers-list [class^="col-"] p {font-size: 13px;top: 60px;width: 82%;}
  
  section.sponsers-list [class^="col-"] i {
      left: 30px;
  }
  section.cta-book a {
      padding: 9px 25px;
  }
  footer ul li i {
      position: relative;
      top: 0px;
  }
  div#testimonails-slider .item p {
      height: auto;
  }
  }